import React from 'react';
import { shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';
import withLayoutProps from '../../../hocs/withLayoutProps';
import BuyButtons from '../BuyButtons';
import { Text } from './ProductInfoBar.styles';
var ProductInfoBar = function ProductInfoBar(_ref) {
  var _product$price, _product$price2;
  var data = _ref.data,
    structure = _ref.structure,
    staticProps = _ref["static"];
  var _data$product = data.product,
    product = _data$product === void 0 ? {} : _data$product;
  var servicesPageLink = staticProps.servicesPageLink;
  var title = [product == null ? void 0 : product.title, product == null ? void 0 : product.reference].filter(Boolean).join(' - ') || '';
  return React.createElement(React.Fragment, null, React.createElement(Text, {
    "data-testid": "product-title"
  }, title), (product == null ? void 0 : product.available) && React.createElement(Box, {
    color: "primary.base",
    fontSize: [4, 4, 5, 5],
    lineHeight: "fontSize"
  }, React.createElement(Box, {
    fontWeight: ['regular', 'regular', 'bold', 'bold'],
    mr: 2,
    "data-testid": "product-price"
  }, currencyFormatter({
    value: (product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.bestPrice) || (product == null ? void 0 : (_product$price2 = product.price) == null ? void 0 : _product$price2.price)
  }))), React.createElement(BuyButtons, {
    data: data,
    "static": {
      bagButton: {
        icon: {
          height: 24,
          name: 'ShoppingBag',
          width: 24
        },
        label: 'Adicionar à Sacola',
        size: 'medium'
      },
      hideFloatButton: true,
      servicesPageLink: servicesPageLink
    },
    structure: structure,
    layout: {
      padding: 0,
      width: 'fit-content'
    }
  }));
};
process.env.NODE_ENV !== "production" ? ProductInfoBar.propTypes = {
  data: shape({
    product: shape({
      reference: string,
      title: string
    })
  }),
  "static": shape({}),
  structure: shape({
    config: shape({
      cartUrl: shape({
        guarantee: string
      })
    })
  })
} : void 0;
ProductInfoBar.defaultProps = {
  data: {},
  "static": {},
  structure: {
    config: {
      cartUrl: {
        guarantee: ''
      }
    }
  }
};
ProductInfoBar.ssr = false;
ProductInfoBar.displayName = 'ProductInfoBar';
export default withLayoutProps(ProductInfoBar);